import React from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

const PrivacyPage = () => {
  return (
    <div className="container min-h-screen px-4 mx-auto">
      <Header
      // title="Privacy Policy – Inszu Life Insurance"
      // description="Protecting your private information is our priority. Inszu LLC (“Company” or “We”) respects your privacy and is committed to protecting it through our compliance with this policy.  This Privacy Policy applies to inszu.com, all its subdomains (“Website”) and Lobis mobile app (“App”) and describes (i) the types of information we may collect from you or that you may provide when you visit the Website and (ii) our practices for collecting, using, maintaining, protecting, and disclosing that information."
      />
      <section>
        <div className="py-4 md:py-16">
          <h1 className="pt-8 pb-8 text-3xl text-center">Privacy Policy</h1>
          <div className="p-8 text-left bg-white rounded inszu-shadow">
            <p className="leading-relaxed text-ebony/70">
              Inszu LLC (“<strong>Company</strong>” or “<strong>We</strong>”) respects your privacy and are committed to
              protecting it through our compliance with this policy. Protecting your private information is our
              priority. This Privacy Policy applies to inszu.com, all its subdomains (“<strong>Website</strong>”) and
              Lobis mobile app (“<strong>App</strong>”), and describes (i) the types of information we may collect from
              you or that you may provide when you visit the Website and (ii) our practices for collecting, using,
              maintaining, protecting, and disclosing that information.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">This policy applies to information we collect:</h3>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>On this Website.</li>
                <li>In email, text, and other electronic messages between you and this Website.</li>
                <li>
                  Through the Company’s mobile and desktop applications, which provide dedicated non-browser-based
                  interaction between you and the Company.
                </li>
                <li>
                  When you interact with our advertising and applications on third-party websites and services, if those
                  applications or advertising include links to this policy.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">It does not apply to information collected by:</p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  us offline or through any other means, including on any other website operated by Company or any third
                  party; or
                </li>
                <li>
                  any third party, including through any application or content (including advertising) that may link to
                  or be accessible from or on the Website.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              Please read this policy carefully to understand our policies and practices regarding your information and
              how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
              Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from
              time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes
              is deemed to be acceptance of those changes, so please check the policy periodically for updates.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Information We Collect About You and How We Collect It </h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We do not collect any personal information about you unless you voluntarily provide it to us. However, you
              may be required to provide certain personal information to us. As such, we collect several types of
              information from and about users of our Website to operate the Website and deliver the services you have
              requested, including information:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  by which you may be personally identified, such as name, postal address, e-mail address, telephone
                  number, social security number or any other information the website collects that is defined as
                  personal or personally identifiable information under an applicable law any other identifier by which
                  you may be contacted online or offline (“personal information”);{' '}
                </li>
                <li>
                  that is about you but individually does not identify you, such as medical conditions, profession, and
                  medications; and/or
                </li>
                <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">We collect this information:</p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information collected automatically may include usage
                  details, IP addresses, and information collected through cookies, web beacons, and other tracking
                  technologies.
                </li>
                <li>From third parties, for example, our business partners.</li>
              </ul>
            </div>
            <h3 className="pt-12 pb-4 text-3xl">Information You Provide to Us</h3>
            <p className="my-4 leading-relaxed text-ebony/70">
              The information we collect on or through our Website may include:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  Information that you provide by filling in forms on our Website. This includes information provided at
                  the time of requesting services or information concerning our services, signing up for special offers
                  from selected third parties, or for registering for an account on the Website. We may also ask you for
                  information when you enter a contest or promotion sponsored by us, and when you report a problem with
                  our Website.
                </li>
                <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                <li>
                  Credit card or other payment information submitted when ordering and purchasing products and services
                  on our website to with we will use your information for, but not limited to, communicating with you in
                  relation to services and/or products you have requested from us.
                </li>
                <li>
                  Details of transactions you carry out through our Website and of the fulfillment of your orders or
                  requests for quotation. You may be required to provide financial information before placing an order
                  through our Website.
                </li>
                <li>Your search queries on the Website.</li>
              </ul>
            </div>
            <h3 className="pt-12 pb-4 text-3xl">
              Information We Collect Through Automatic Data Collection Technologies
            </h3>
            <p className="my-4 leading-relaxed text-ebony/70">
              As you navigate through and interact with our Website, we may use automatic data collection technologies
              to collect certain information about your computer equipment and software, browsing actions, and patterns,
              including:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  Details of your visits to our Website, including traffic data, location data, and other communication
                  data and the resources that you access and use on the Website.
                </li>
                <li>
                  Information about your computer and internet connection, including your IP address, accesst times,
                  domain names, and referring website addresses, operating system, and browser type.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              This information is used for the operation of the Company, to maintain quality of our services, and to
              provide general statistics regarding use of Website. The information we collect automatically is
              statistical data and does not include personal information, but we may maintain it or associate it with
              personal information we collect in other ways or receive from third parties. It helps us to improve our
              Website and to deliver a better and more personalized service, including by enabling us to:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to customize our Website according to your
                  individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Website.</li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              The technologies we use for this automatic data collection may include:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of
                  your computer by a webpage server. You may refuse to accept browser cookies by activating the
                  appropriate setting on your browser. However, if you select this setting you may be unable to access
                  certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse
                  cookies, our system will issue cookies when you direct your browser to our Website. The use of cookies
                  is discussed in greater detail below.
                </li>
                <li>
                  <strong>Flash Cookies.</strong> Certain features of our Website may use local stored objects (or Flash
                  cookies) to collect and store information about your preferences and navigation to, from, and on our
                  Website. Flash cookies are not managed by the same browser settings as are used for browser cookies.
                  For information about managing your privacy and security settings for Flash cookies, see .
                </li>
                <li>
                  <strong>Web Beacons.</strong> Pages of our the Website [and our e-mails] may contain small electronic
                  files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                  permit the Company, for example, to count users who have visited those pages or [opened an email] and
                  for other related website statistics (for example, recording the popularity of certain website content
                  and verifying system and server integrity).
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              We do not collect personal information automatically, but we may tie this information to personal
              information about you that we collect from other sources or you provide to us.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Use of Cookies</h3>
            <p className="my-4 leading-relaxed text-ebony/70">
              As indicated above, the Website may use cookies to help you personalize your online experience. Cookies
              cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you,
              and can only be read by a web server in the domain that issued the cookie to you. One of the primary
              purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to
              tell the website server that you have returned to a specific page. For example, if you personalize the
              Website or register with the Company for its services, a cookie helps the Company to recall your specific
              information on subsequent visits. This simplifies the process of recording your personal information, such
              as billing addresses, shipping addresses, and so on. When you return to the Website, the information you
              previously provided can be retrieved, so you can easily use the Company’s features that you customized.
              You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
              can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
              cookies, you may not be able to fully experience the interactive features of the Company’s services or the
              Website.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Third-Party Use of Cookies and Other Tracking Technologies</h3>
            <p className="my-4 leading-relaxed text-ebony/70">
              Some content or applications, including advertisements, on the Website are served by third-parties,
              including advertisers, ad networks and servers, content providers, and application providers. These third
              parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect
              information about you when you use our website. The information they collect may be associated with your
              personal information or they may collect information, including personal information, about your online
              activities over time and across different websites and other online services. They may use this
              information to provide you with interest-based (behavioral) advertising or other targeted content.
            </p>
            <p className="my-4 leading-relaxed text-ebony/70">
              We do not control these third parties’ tracking technologies or how they may be used. If you have any
              questions about an advertisement or other targeted content, you should contact the responsible provider
              directly. For information about how you can opt out of receiving targeted advertising from many providers,
              see Choices About How We Use and Disclose Your Information
            </p>
            <h3 className="pt-12 pb-4 text-3xl">How We Use Your Information</h3>
            <p className="my-4 leading-relaxed text-ebony/70">
              We use information that we collect about you or that you provide to us, including any personal
              information:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>To present our Website and its contents to you.</li>
                <li>To inform you of other products or services available from the Company and its affiliates.</li>
                <li>To provide you with information, products, or services that you request from us.</li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>
                  To provide you with notices about your account or subscription, including expiration and renewal
                  notices.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising from any contracts entered into between
                  you and us, including for billing and collection.
                </li>
                <li>
                  To notify you about changes to our Website or any products or services we offer or provide though it.
                </li>
                <li>To allow you to participate in interactive features on our Website.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>For any other purpose with your consent.</li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              We may also use your information to contact you about our own services that may be of interest to you. If
              you do not want us to use your information in this way, please check the relevant box located on the form
              on which we collect your data. For more information, see Choices About How We Use and Disclose Your
              Information.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We do not sell, rent or lease our customer lists to third parties. We may share data with trusted partners
              to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange
              for deliveries. All such third parties are prohibited from using your personal information except to
              provide these services to the Company, and they are required to maintain the confidentiality of your
              information.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Disclosure of Your Information</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We may disclose aggregated information about our users, and information that does not identify any
              individual, without restriction.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We may disclose personal information that we collect or you provide as described in this privacy policy:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>To our subsidiaries and affiliates.</li>
                <li>
                  To contractors, service providers, and other third parties we use to support our business and who are
                  bound by contractual obligations to keep personal information confidential and use it only for the
                  purposes for which we disclose it to them.
                </li>
                <li>
                  To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going
                  concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
                  held by the Company about our Website users is among the assets transferred.
                </li>
                <li>
                  To third parties to market their products or services to you if you have consented to or not opted out
                  of these disclosures. For more information, see .
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              We may also disclose your personal information, without notice:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  To comply with any court order, law, or legal process, including to respond to any government or
                  regulatory request.
                </li>
                <li>To protect the personal safety of users of the Company or the public.</li>
                <li>
                  To enforce or apply our terms of use or Privacy Policy and other agreements, including for billing and
                  collection purposes.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                  Company, our customers, or others. This includes exchanging information with other companies and
                  organizations for the purposes of fraud protection and credit risk reduction.
                </li>
              </ul>
            </div>
            <h3 className="pt-12 pb-4 text-3xl">Choices About How We Use and Disclose Your Information</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              We strive to provide you with choices regarding the personal information you provide to us. We have
              created mechanisms to provide you with the following control over your information:
            </p>
            <div className="px-8 ">
              <ul className="leading-relaxed list-disc text-ebony/70">
                <li>
                  <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some
                  browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash
                  cookie settings, visit the Flash player settings page on Adobe’s . If you disable or refuse cookies,
                  please note that some parts of this site may then be inaccessible or not function properly.
                </li>
                <li>
                  <strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us to
                  share your personal information with unaffiliated or non-agent third parties for promotional purposes,
                  you can opt-out by checking the relevant box located on the form on which we collect your data. You
                  can also always opt-out by logging into the Website and adjusting your user preferences in your
                  account profile by checking or unchecking the relevant boxes or by sending us an email stating your
                  request to info@inszu.com.
                </li>
                <li>
                  <strong>Promotional Offers from the Company.</strong> If you do not wish to have your email address or
                  other contact information used by the Company to promote our own or third parties’ products or
                  services, you can opt-out by checking the relevant box located on the form on which we collect your
                  data or at any other time by logging into the Website and adjusting your user preferences in your
                  account profile by checking or unchecking the relevant boxes or by sending us an email stating your
                  request to info@inszu.com. If we have sent you a promotional email, you may send us a return email
                  asking to be omitted from future email distributions.
                </li>
                <li>
                  <strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or
                  that you provide to us to deliver advertisements according to our advertisers’ target-audience
                  preferences, you can opt-out by contacting us at info@inszu.com.
                </li>
              </ul>
            </div>
            <p className="my-4 leading-relaxed text-ebony/70">
              We do not control third parties’ collection or use of your information to serve interest-based
              advertising. However, these third parties may provide you with ways to choose not to have your information
              collected or used in this way.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Accessing and Correcting Your Information</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You can review and change your personal information by logging into the Website and visiting your account
              profile page.
            </p>
            <p className="mb-4 leading-relaxed text-ebony/70">
              You may also send us an email at info@inszu.com to request access to, correct or delete any personal
              information that you have provided to us. We cannot delete your personal information except by also
              deleting your user account. We may not accommodate a request to change information if we believe the
              change would violate any law or legal requirement or cause the information to be incorrect.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Your California Privacy Rights</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              California Civil Code Section § 1798.83 permits users of our Website that are California residents to
              request certain information regarding our disclosure of personal information to third parties for their
              direct marketing purposes. To make such a request, please send an email to info@inszu.com or write us at:
              Inszu LLC, 2801 E. Camelback Rd., Second Floor, Phoenix, AZ 85016.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Data Security</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Company secures your personal information from unauthorized access, use, or disclosure. The Company
              uses Secure Sockets Layer (SSL) protocol (“<strong>SSL </strong>Protocol”) and data encryption when
              personal information (such as a credit card number) is transmitted to other websites.
            </p>

            <p className="mb-4 leading-relaxed text-ebony/70">
              We have implemented measures designed to secure your personal information from accidental loss and from
              unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our
              secure servers or third party servers provided by third party hosting vendors with whom we have
              contracted. In each instance, your data is stored behind firewalls. Any transactions will be encrypted
              using SSL technology.
            </p>

            <p className="mb-4 leading-relaxed text-ebony/70">
              The safety and security of your information also depends on you. Where we have given you (or where you
              have chosen) a password for access to certain parts of our Website, you are responsible for keeping this
              password confidential. We ask you not to share your password with anyone.
            </p>

            <p className="mb-4 leading-relaxed text-ebony/70">
              We strive to take appropriate security measures to protect against unauthorized access to or alteration of
              your personal information. Unfortunately, the transmission of information via the internet or wireless
              network is not completely secure. Although we do our best to protect your personal information, we cannot
              guarantee the security of your personal information transmitted to our Website. As a result, any
              transmission of personal information is at your own risk, and you acknowledge that: (a) there are security
              and privacy limitations inherent to the Internet which are beyond our control; and (b) security,
              integrity, and privacy of any and all information and data exchanged between you and us through this Site
              cannot be guaranteed. We are not responsible for circumvention of any privacy settings or security
              measures contained on the Website.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Changes to Our Privacy Policy</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Company reserves the right to change this Privacy Policy from time to time. We will notify you about
              significant changes in the way we treat personal information by sending a notice to the primary email
              address specified in your account, by placing a prominent notice on our site, and/or by updating any
              privacy information on this page. If we make material changes to how we treat our users’ personal
              information, we will notify you through a notice on the Website home page. The date the privacy policy was
              last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date
              active and deliverable email address for you, and for periodically visiting our Website and this privacy
              policy to check for any changes. Your continued use of the Website and/or Services available through the
              Website after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy;
              and (b) agreement to abide and be bound by that Policy.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Children Under the Age of 13</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              Our Website is not intended for children under 13 years of age. No one under age 13 may provide any
              personal information to or on the Website. We do not knowingly collect personal information from children
              under 13. If you are under 13, do not use or provide any information on this Website or on or through any
              of its features, use any Company app, use any of the interactive or public comment features of this
              Website or provide any information about yourself to us, including your name, address, telephone number,
              email address, or any screen name or user name you may use. If we learn we have collected or received
              personal information from a child under 13 without verification of parental consent, we will delete that
              information. If you believe we might have any information from or about a child under 13, please contact
              us at info@inszu.com.
            </p>
            <h3 className="pt-12 pb-4 text-3xl">Contact Information</h3>
            <p className="mb-4 leading-relaxed text-ebony/70">
              The Company welcomes your questions or comments regarding this Privacy Policy. You may contact us at Inszu
              LLC, 2801 E. Camelback Rd., Second Floor, Phoenix, AZ 85016, Email Address: Info@inszu.com
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PrivacyPage
